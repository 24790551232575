import Seo from "./Seo";
import { Link } from "react-router-dom";
import { useState } from "react";
import useScript from "./useScript";
import { useMutation } from "react-query";
import ApiClient from "./ApiClient";
import { toast } from 'react-toastify';
import { signInWithRedirect } from "aws-amplify/auth";

function Signup() {
  const sendSignUpForm = useMutation({
    mutationFn: (data) => {
      if (data) {
        return ApiClient.send('POST', '/signup', data, false);
      }
    },
    onSuccess: async (data, variables, context) => {
      const res = await data.json();
      if (data.status === 200) {
        if (res.notices !== undefined) {
          //TODO: need protocol change
        } else {
          setName("");
          setEmail("");
          setPhone("");
          setLocation("");
          setCode("");
          setIsValid("");
          setFieldErrors(res);
          setPrivacy(false);
          setTerms(false);

          // continue with Google, or LinkedIn login
          signInWithRedirect({});
        }
      } else {
        setFieldErrors(res);
        toast.error(res["overall"].join('. '));
      }
    }
  });


  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  const [code, setCode] = useState("");

  const [privacy, setPrivacy] = useState(false);
  const [terms, setTerms] = useState(false);

  const [isValid, setIsValid] = useState("");

  const defaultInvalidFeedback = "This field needs a correction";
  const defaultInvalidCheckboxFeedback = "You must agree before submitting.";

  const recaptchaPublicKey = ApiClient.config.recaptcha_public_key;
  useScript("https://www.google.com/recaptcha/api.js?render=" + recaptchaPublicKey);

  // Response state
  const [fieldErrors, setFieldErrors] = useState({});

  let handleSubmit = async (e) => {
    e.preventDefault();

    // Check validity locally first
    const form = e.target;
    if (!form.checkValidity()) {
      e.stopPropagation();
      form.classList.add('was-validated');
      return;
    } else {
      // If it is overall okay - send it to backend for further validation
      form.classList.remove('was-validated');
      setIsValid("is-valid");
    }

    window.grecaptcha.ready(_ => {
      window.grecaptcha
        .execute(recaptchaPublicKey, { action: "signup" })
        .then(token => {
          handleSubmitWithRecaptcha(token);
        })
    });
  }

  let handleSubmitWithRecaptcha = async (recaptchaToken) => {
    sendSignUpForm.mutate({
      full_name: name,
      email: email,
      phone: phone,
      location: location,
      has_accepted_privacy: privacy,
      has_accepted_terms: terms,
      code: code,
      recaptchaToken: recaptchaToken
    });
  };


  return (
    <div className="container">
      <Seo title="Sign up" />

      <div className="row text-center">
        <div className="col-sm-2">&nbsp;</div>

        <div className="col-sm-8">
          <h2>Sign up</h2>
            <p className="alert alert-info" role="alert"><i className="bi bi-info-circle me-2 text-primary" /><Link to="/elevate">Elevate</Link> section describes where you can obtain peer group access code</p>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-2">&nbsp;</div>

        <div className="col-sm-8">
          <form className="row g-3" noValidate onSubmit={handleSubmit} >

            <div className={"input-group " + (fieldErrors["Name"] && fieldErrors["Name"].length > 0 ? "is-invalid" : isValid)}>
              <label className="col-12 control-label">Full name</label>
              <input id="validationName" className={"col-12 form-control validate_required " + (fieldErrors["Name"] && fieldErrors["Name"].length > 0 ? "is-invalid" : isValid)} type="text" placeholder="Full name" aria-describedby="validationNameFeedback" required="required" value={name} onChange={(e) => setName(e.target.value)} />
              <div id="validationNameFeedback" className="invalid-feedback">{fieldErrors["Name"] && fieldErrors["Name"].length > 0 ? fieldErrors["Name"].join(". ") : defaultInvalidFeedback}</div>
            </div>

            <div className={"input-group " + (fieldErrors["Email"] && fieldErrors["Email"].length > 0 ? "is-invalid" : isValid)}>
              <label className="control-label col-12">Email address</label>
              <input className={"form-control col-12 validate_email " + (fieldErrors["Email"] && fieldErrors["Email"].length > 0 ? "is-invalid" : isValid)} aria-describedby="validationEmailFeedback" placeholder="Email associated with Google or LinkedIn account" required="required" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
              <div id="validationEmailFeedback" className="invalid-feedback">{fieldErrors["Email"] && fieldErrors["Email"].length > 0 ? fieldErrors["Email"].join(". ") : defaultInvalidFeedback}</div>
            </div>

            <div className={"input-group " + (fieldErrors["Phone"] && fieldErrors["Phone"].length > 0 ? "is-invalid" : isValid)}>
              <label className="control-label col-12">Phone number</label>
              <input className={"form-control col-12 validate_required " + (fieldErrors["Phone"] && fieldErrors["Phone"].length > 0 ? "is-invalid" : isValid)} aria-describedby="validationPhoneFeedback" placeholder="Phone number (digits without separators)" required="required" type="number" min="1000000000" max="999999999999999" value={phone} onChange={(e) => setPhone(e.target.value)} />
              <div id="validationPhoneFeedback" className="invalid-feedback">{fieldErrors["Phone"] && fieldErrors["Phone"].length > 0 ? fieldErrors["Phone"].join(". ") : defaultInvalidFeedback}</div>
            </div>

            <div className={"input-group " + (fieldErrors["Location"] && fieldErrors["Location"].length > 0 ? "is-invalid" : isValid)}>
              <label className="col-12 control-label">City and state</label>
              <input id="validationLocation" className={"col-12 form-control validate_required " + (fieldErrors["Location"] && fieldErrors["Location"].length > 0 ? "is-invalid" : isValid)} type="text" placeholder="City and state" aria-describedby="validationLocationFeedback" required="required" value={location} onChange={(e) => setLocation(e.target.value)} />
              <div id="validationLocationFeedback" className="invalid-feedback">{fieldErrors["Location"] && fieldErrors["Location"].length > 0 ? fieldErrors["Location"].join(". ") : defaultInvalidFeedback}</div>
            </div>

            <div className={"input-group " + (fieldErrors["Code"] && fieldErrors["Code"].length > 0 ? "is-invalid" : isValid)}>
              <label className="col-12 control-label">Peer group access code</label>
              <input id="validationCode" className={"col-12 form-control validate_required " + (fieldErrors["Code"] && fieldErrors["Code"].length > 0 ? "is-invalid" : isValid)} type="text" placeholder="Code" aria-describedby="validationCodeFeedback" required="required" value={code} onChange={(e) => setCode(e.target.value)} />
              <div id="validationCodeFeedback" className="invalid-feedback">{fieldErrors["Code"] && fieldErrors["Code"].length > 0 ? fieldErrors["Code"].join(". ") : defaultInvalidFeedback}</div>
            </div>

            <div className={"form-check px-5 " + (fieldErrors["Privacy"] && fieldErrors["Privacy"].length > 0 ? "is-invalid" : isValid)}>

              <input id="validationPrivacy" className={"form-check-input validate_required " + (fieldErrors["Privacy"] && fieldErrors["Privacy"].length > 0 ? "is-invalid" : isValid)} type="checkbox" aria-describedby="validationPrivacyFeedback" required="required" checked={privacy} onChange={() => setPrivacy(!privacy)} /> <label className="form-check-label" htmlFor="validationPrivacy">By signing up, you are agreeing and accepting <a href='/privacy' target="_blank">privacy policy</a></label>
              <div id="validationPrivacyFeedback" className="invalid-feedback">{fieldErrors["Privacy"] && fieldErrors["Privacy"].length > 0 ? fieldErrors["Privacy"].join(". ") : defaultInvalidCheckboxFeedback}</div>
            </div>

            <div className={"form-check px-5 " + (fieldErrors["Terms"] && fieldErrors["Terms"].length > 0 ? "is-invalid" : isValid)}>
              <input id="validationTerms" className={"form-check-input validate_required " + (fieldErrors["Terms"] && fieldErrors["Terms"].length > 0 ? "is-invalid" : isValid)} type="checkbox" aria-describedby="validationTermsFeedback" required="required" checked={terms} onChange={() => setTerms(!terms)} /> <label className="form-check-label">By signing up, you are agreeing and accepting <a href='/terms' target="_blank">terms of use</a></label>
              <div id="validationTermsFeedback" className="invalid-feedback">{fieldErrors["Terms"] && fieldErrors["Terms"].length > 0 ? fieldErrors["Terms"].join(". ") : defaultInvalidCheckboxFeedback}</div>
            </div>

            <div className="input-group d-grid gap-2 col-6">
              <button disabled={sendSignUpForm.isLoading} className="btn btn-lg btn-primary" type="submit"> {sendSignUpForm.isLoading ? "Signing up..." : "Sign up"}</button>
            </div>

          </form>
        </div>

      </div>
    </div>
  );
}

export default Signup;
