import Seo from "./Seo";

function Archive() {
  return (
    <div className="container">
      <Seo title="Archive" />
        <h2>Archive</h2>
        <h3>Permanently Deprecated</h3>
        <ul>
        <li><a href="https://github.com/AnyIntelli/cancermath.org/wiki/Data-Analysis">Data Analysis</a></li>
        <li><a href="https://github.com/AnyIntelli/cancermath.org/wiki/Tumor-analysis-software">aidScans</a></li>
        <li><a href="https://github.com/AnyIntelli/cancermath.org/wiki/aidCells">aidCells</a></li>
        <li><a href="https://github.com/AnyIntelli/cancermath.org/wiki/aidReady">aidReady</a></li>
        <li><a href="https://nim.ai1.co">Chess Puzzles</a></li>
        </ul>

        <h3>Suspended</h3>
        <ul>
          <li><a href="https://www.facebook.com/AnyIntelli">Facebook page</a></li>
        </ul>
    </div>
  );
}

export default Archive;
