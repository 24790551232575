import Seo from "./Seo";
import { Link } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";

function Elevate() {
  return (
    <div className="container">
      <Seo title="Elevate" />
      <h2>Elevate - 1, 2, 3<sup>beta</sup></h2>

      <div className="row featurette text-center mb-3">
        <div className="col-md-4 text-elevate-900 mb-1">

          <div className="card h-100 bc-elevate-premium1 shadow">
            <div className="card-body">
              <h4 className="card-title">Play</h4>
              <p className="card-text">Demo what you know.</p>
              <p className="card-text">Score, rank and stay human.</p>
              <p className="card-text">Interview with teams which value you.</p>
            </div>
            <div className="card-footer text-center bg-transparent border-0">
              <Link className="btn btn-sm btn-success" to="/signup/elevate-1">Sign up</Link> &nbsp;
              <a className="btn btn-sm btn-primary" href="#player">Learn more</a>
            </div>
          </div>
        </div>

        <div className="col-md-4 text-dark mb-1">
          <div className="card h-100 bc-elevate-premium2 shadow">
            <div className="card-body">
              <h4 className="card-title">Train</h4>
              <p className="card-text">Get involved.</p>
              <p className="card-text">Understand your craft better.</p>
              <p className="card-text">Train interested groups.</p>
            </div>
            <div className="card-footer text-center bg-transparent border-0">
              <Link className="btn btn-sm btn-success" to="/signup/elevate-2">Sign up</Link> &nbsp;
              <a className="btn btn-sm btn-primary" href="#trainer">Learn more</a>
            </div>

          </div>
        </div>

        <div className="col-md-4 mb-1">
          <div className="card h-100 bc-elevate-premium3 shadow">
            <div className="card-body">
              <h4 className="card-title">Spearhead. Coach</h4>
              <p>Leadership of service.</p>
              <p>Create environment of learning.</p>
              <p>Armor for your ideas.</p>
            </div>
            <div className="card-footer text-center bg-transparent border-0">
              <Link className="btn btn-sm btn-success" to="/signup/elevate-3">Sign up</Link> &nbsp;
              <a className="btn btn-sm btn-primary" href="#coach">Learn more</a>
            </div>
          </div>
        </div>
      </div>

      <div className="row featurette">

        <div className="col-md-7">
          <img className="featurette-image img-fluid" src="/img/main/workshops2.jpg" alt="Growth" width="800px" height="800px" />
        </div>

        <div className="col-md-5">
          <h3>Overview</h3>
          <p>AnyIntelli Elevate is focused on setting good incentives and meaningful productive career for seasoned professionals through sustainable development.</p>
          <p>Repetition and application of your strength starting from improved job search, to practicing your domain,
            and to spearheading innovative products elevating productivity via compounding cycle.</p>

          <p>Gradually prepare path from improving risk-averse jobs on meaningful mission, to training, and mastering a blueprint for spearheading.</p>
          <p>When there is no ladder, there is clear goal to increase productivity of end customers.</p>

          <p>These programs help iteratively understand where you are, and direct where you are going to by bridging gap between steady job
            and putting everything on a startup idea.</p>

          <p>See <a href="#how">"How does this program work?"</a> below for details.</p>

        </div>

        <div className="row">
          <div className="col-md-4 mb-1">
            <div className="card h-100 bc-secondary shadow">
              <div className="card-header text-center">
                <h3><span className="first-letter" id="player">1</span> Player</h3>
              </div>

              <div className="card-body">
                <h5 className="card-title text-center" id="elevate1">Elevate your interview</h5>
                <p>
                  <i className="bi bi-info" /> The path can start as early as organized interviewing process with companies which are interested in your skills as you demo them.
                </p>
                <p>
                  <i className="bi bi-info" /> Rank among peers, get feedback, refine your skills. We all know the interview process is broken.
                  This is our offering to individuals and companies to fix it.
                </p>

                <p>
                  <i className="bi bi-info" /> We all know the interview process is broken. This is our offering to individuals and companies to fix it.
                </p>

              </div>
              <div className="card-footer text-center bg-transparent border-0">
                <Link className="btn btn-md btn-success" to="/signup/elevate-1">Sign up</Link>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-1">
            <div className="card h-100 bc-secondary shadow">
              <div className="card-header text-center">
                <h3><span className="first-letter" id="trainer">2</span> Trainer</h3>
              </div>
              <div className="card-body">
                <h5 className="card-title text-center" id="elevate2">Elevate others, learn yourself</h5>
                <p><i className="bi bi-info" />
                  Well tuned players can start training using their demos to refine it even further.
                </p>
                <p><i className="bi bi-info" />
                  One understands it better when they explain it.
                </p>
                <p><i className="bi bi-info" />
                  Get ready for spearheading.
                </p>
              </div>
              <div className="card-footer text-center bg-transparent border-0">
                <Link className="btn btn-md btn-success" to="/signup/elevate-2">Sign up</Link>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-1">
            <div className="card h-100 bc-secondary shadow">
              <div className="card-header text-center">
                <h3><span className="first-letter" id="coach">3</span> Spearhead. Coach</h3>
              </div>
              <div className="card-body">
                <h5 className="card-title text-center" id="elevate3">Elevate startup experience</h5>
                <p><i className="bi bi-info" /> As you go from a skills training to a complete blueprint in your area of interest you are getting into proper state when you can innovate spearheading startups.</p>
                <p><i className="bi bi-info" /> Discover opportunities to build, produce, or sponsor new ideas.</p>
                <p><i className="bi bi-info" /> Scalability is differentiating factor. Go beyond presentation.</p>
              </div>
              <div className="card-footer text-center bg-transparent border-0">
                <Link className="btn btn-md btn-success" to="/signup/elevate-3">Sign up</Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row featurette">
          <h3 id="how">How does this program work?</h3>

          <div className="col-md-7">
            <img className="featurette-image img-fluid" src="/img/programs/elevate_4_tesserae.png" alt="Growth" width="800px" height="800px" />
          </div>
          <div className="col-md-5">
            <p>There are four guiding steppingstones on this path:</p>
            <ul>
              <li className="list-group-item"><span className="text-success"><i className="bi bi-puzzle-fill me-2" />Who you are?</span> - to establish your developer identity</li>
              <li className="list-group-item"><span className="text-info"><i className="bi bi-puzzle-fill me-2" />What is your sport?</span> - what parts and components of a SaaS offering are you able to build on your own</li>
              <li className="list-group-item"><span className="text-primary"><i className="bi bi-puzzle-fill me-2" />What is your strength?</span> - show what strength you can train others by example in context of getting to five nines (99.999%) under "what if" analysis</li>
              <li className="list-group-item"><span className="text-purple"><i className="bi bi-puzzle-fill me-2" />What is your vision?</span> - demonstrate a playbook(s) to unite individual strengths into overall sustainable team</li>
            </ul>

            <p>
              Completion of each step gives a tessera of developer identity mosaic for your card and the quest is to obtain two to four tesserae.
            </p>

          </div>

        </div>

        <div className="row featurette">
          <div className="col-md-3">
            <h4 className="text-success"><i className="bi bi-puzzle-fill me-2" />Who you are?</h4>
            <p>Establish developer identity as it is.
              Create an initial card showing your presentations online.
              Set goals. Vaguely identify peer group, strength, identify weakness.
              Get personalized feedback on your way to a peer group.</p>

            <p><Link to="/signup">Sign up here</Link>. If you have not found a sponsored peer group, put “SELF-4E”.</p>
          </div>
          <div className="col-md-3">
            <h4 className="text-info"><i className="bi bi-puzzle-fill me-2" />What is your sport?</h4>
            <p>Which game is right for you. Here where innovation is built upon.</p>
            <p>Qualify or form a league around a question what's the best way to demo oneself.</p>
            <p>Peer group: can be founded based on skill or based on demand from a company. Relevant peer groups will be emailed only if you enable notifications.</p>
            <p>We encourage you to subscribe to our <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/anyintelli">LinkedIn page </a>as some opportunities might be missed by matching algorithm.</p>

          </div>
          <div className="col-md-3">
            <h4 className="text-primary"><i className="bi bi-puzzle-fill me-2" />What is your strength?</h4>
            <p>Get feedback professionally from peers. Elevate your skills. Constant scoring gets you into sponsored program, or self-sponsored train-mode. Advancing you to third tessera (Elevate 2). Training.</p>
          </div>
          <div className="col-md-3">
            <h4 className="text-purple"><i className="bi bi-puzzle-fill me-2" />What is your vision?</h4>
            <p>As you approach full-scale framework, and score based on feedback well. You get towards the fourth tessera (Elevate 3). Coaching.</p>
          </div>
          <div className="col-md-12">
            <h4>Remarks</h4>
            <p>A. Make sure to be aligned with growth environment <Link to="/practice">vision</Link>. No matter where in the process you are at.</p>
            <p>B. Capability of spearheading a new idea is key objective. Though, as your life priorities change you can transition from stage to stage too.</p>
            <p>C. Once you are <Link to="/signup">signed up</Link> for an account indicate in your profile which programs you are interested in. System is adaptive.</p>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Elevate;
