import Seo from "./Seo";
import { useState } from "react";
import useScript from './useScript';
import { useParams } from "react-router-dom";
import ApiClient from "./ApiClient";
import { useMutation } from "react-query";
import { toast } from 'react-toastify';

function Demo() {
  let { lead = "" } = useParams();

  const sendContactForm = useMutation({
    mutationFn: (data) => {
      if (data) {
        return ApiClient.send('POST', '/contact', data, false);
      }
    },
    onSuccess: async (data, variables, context) => {
      const res = await data.json();
      if (data.status === 200) {
        if (res.notices !== undefined) {
          //TODO: need protocol change
        } else {
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
          setGoal("");
          setFieldErrors(res);
          toast.success(res["overall"].join('. '));
        }
      } else {
        setFieldErrors(res);
        toast.error(res["overall"].join('. '));
      }
    }
  });

  const goals =
  {
    "":
    {
      "description": "-- Please, select one --",
      "img": "/img/programs/omelette.jpg"
    },
    "elevate":
    {
      "description": "Elevate: learn how this program works for you",
      "img": "/img/main/workshops2.jpg"
    },
    "produce":
    {
      "description": "Produce: pitch a product idea",
      "img": "/img/main/ata.jpg"
    },
    "sponsor":
    {
      "description": "Sponsor: other",
      "img": "/img/main/ata.jpg"
    },
    "ata-flip":
    {
      "description": "ATA: Flip a SaaS business",
      "img": "/img/main/ata.jpg"
    },
    "ata-saas":
    {
      "description": "ATA: SaaS acquisition",
      "img": "/img/main/ata.jpg"
    },
    "ata-alt":
    {
      "description": "ATA: Alternative lab - second tech option",
      "img": "/img/main/ata.jpg"
    },
    "workshops-1":
    {
      "description": "Workshops: Startup blueprint",
      "img": "/img/main/workshops2.jpg"
    },
    "workshops-2":
    {
      "description": "Workshops: Startup blueprint+",
      "img": "/img/main/workshops2.jpg"
    },
    "workshops-3":
    {
      "description": "Workshops: Startup blueprint premium+",
      "img": "/img/main/workshops2.jpg"
    },
    "general":
    {
      "description": "General question",
      "img": "/img/programs/omelette.jpg"
    },
  };

  // Requests state
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [goal, setGoal] = useState(goals[lead] !== undefined ? lead : "");
  const [message, setMessage] = useState("");

  const defaultInvalidFeedback = "This field needs a correction";

  const recaptchaPublicKey = ApiClient.config.recaptcha_public_key;
  useScript("https://www.google.com/recaptcha/api.js?render=" + recaptchaPublicKey);

  // Response state
  const [fieldErrors, setFieldErrors] = useState({});

  let handleSubmit = async (e) => {
    e.preventDefault();

    // Check validity locally first
    const form = e.target;
    if (!form.checkValidity()) {
      e.stopPropagation();
      form.classList.add('was-validated');
      return;
    } else {
      // If it is overall okay - send it to backend for further validation
      form.classList.remove('was-validated');
    }

    window.grecaptcha.ready(_ => {
      window.grecaptcha
        .execute(recaptchaPublicKey, { action: "contact" })
        .then(token => {
          handleSubmitWithRecaptcha(token);
        })
    });
  }

  let handleSubmitWithRecaptcha = async (recaptchaToken) => {
    sendContactForm.mutate({
      name: name,
      email: email,
      phone: phone,
      message: message,
      goal: goals[goal].description,
      recaptchaToken: recaptchaToken
    });
  };

  return (
    <div className="container">
      <Seo title="Intro & demo" />

      <h2>Intro & demo: "Oh My, Lovely Engineering Treat"</h2>

      <div className="row">
        <div className="col-sm-6">
          <img className="featurette-image img-fluid mt-3" src={goals[goal].img} alt="Growth" width="800px" height="800px" />

          <p>
            Omelette is a way to test a chef for their skills and technique.
            Managing timing, flavor, ingredients, ratios, balance, heat control, pan handling, and other cooking fundamentals
            give a good glimpse into chef's personality and mastery. Simple dish becomes a focused and revealing demonstration
            of what a chef is capable of.
          </p>
          <p>
            Here, at AnyIntelli, an omelette is a metaphor for demonstrating software development expertise.
            From other side, it is a good way for a technology leader to ask about something simple and yet impactful in their organization.
            This gives an ability to establish trust and confirm the fit.
          </p>
        </div>

        <div className="col-sm-6">
          <form className="row g-3" noValidate action="/contact" method="post" onSubmit={handleSubmit} >

            <div className="select-group">
              <label className="col-12 control-label">Interest</label>
              <select defaultValue={goals[lead] !== undefined ? lead : ""} name="goal" id="goalId" className="form-select is-valid validate_required" required="required" onChange={(e) => setGoal(e.target.value)} >

                {Object.keys(goals).map(key => (
                  <option key={key} value={key}>{goals[key].description}</option>
                ))}
              </select>
            </div>

            <div className={"input-group " + (fieldErrors["Name"] && fieldErrors["Name"].length > 0 ? "is-invalid" : "is-valid")}>
              <label className="col-12 control-label">Full name</label>
              <input id="validationName" className={"col-12 form-control validate_required " + (fieldErrors["Name"] && fieldErrors["Name"].length > 0 ? "is-invalid" : "is-valid")} type="text" placeholder="Full name" aria-describedby="validationNameFeedback" required="required" value={name} onChange={(e) => setName(e.target.value)} />
              <div id="validationNameFeedback" className="invalid-feedback">{fieldErrors["Name"] && fieldErrors["Name"].length > 0 ? fieldErrors["Name"].join(". ") : defaultInvalidFeedback}</div>
            </div>

            <div className={"input-group " + (fieldErrors["Email"] && fieldErrors["Email"].length > 0 ? "is-invalid" : "is-valid")}>
              <label className="control-label col-12">Email address</label>
              <input className={"form-control col-12 validate_email " + (fieldErrors["Email"] && fieldErrors["Email"].length > 0 ? "is-invalid" : "is-valid")} aria-describedby="validationEmailFeedback" placeholder="Email address" required="required" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
              <div id="validationEmailFeedback" className="invalid-feedback">{fieldErrors["Email"] && fieldErrors["Email"].length > 0 ? fieldErrors["Email"].join(". ") : defaultInvalidFeedback}</div>
            </div>

            <div className={"input-group " + (fieldErrors["Phone"] && fieldErrors["Phone"].length > 0 ? "is-invalid" : "is-valid")}>
              <label className="control-label col-12">Phone number</label>
              <input className={"form-control col-12 validate_required " + (fieldErrors["Phone"] && fieldErrors["Phone"].length > 0 ? "is-invalid" : "is-valid")} aria-describedby="validationPhoneFeedback" placeholder="Phone number (digits without separators)" required="required" type="number" min="1000000000" max="999999999999999" value={phone} onChange={(e) => setPhone(e.target.value)} />
              <div id="validationPhoneFeedback" className="invalid-feedback">{fieldErrors["Phone"] && fieldErrors["Phone"].length > 0 ? fieldErrors["Phone"].join(". ") : defaultInvalidFeedback}</div>
            </div>

            <div className={"input-group " + (fieldErrors["Message"] && fieldErrors["Message"].length > 0 ? "is-invalid" : "is-valid")}>
              <label className="control-label col-12">Comment*</label>
              <textarea rows="5" className={"col-12 form-control validate_required " + (fieldErrors["Message"] && fieldErrors["Message"].length > 0 ? "is-invalid" : "is-valid")} aria-describedby="validationMessageFeedback" placeholder="What's your 'make me an O.M.L.E.T.' story?" required="required" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
              <div id="validationMessageFeedback" className="invalid-feedback">{fieldErrors["Message"] && fieldErrors["Message"].length > 0 ? fieldErrors["Message"].join(". ") : defaultInvalidFeedback}</div>
            </div>

            <div className="input-group d-grid gap-2">
              <button disabled={sendContactForm.isLoading} className="btn btn-lg btn-dark" type="submit">{sendContactForm.isLoading ? "Sending..." : "Send"}</button>
            </div>

            <p>* - Please, keep NDA-worth materials out of this form; or submit on your own risk.</p>
          </form>

        </div>
      </div>

    </div>
  );
}

export default Demo;
